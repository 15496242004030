import {  Box, Button, Card, Container, Grid, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';

// import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';

import { useState , useEffect } from 'react';
import { signin } from 'src/redux/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Logo from 'src/components/LogoSign';
import { forgotPassword } from 'src/redux/forgotPasswordSlice';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);
const OverviewWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);


function ForgotPassword() {
  const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.forgotPassword);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const forgetSubmit = async () => {
      if(email){
        await dispatch(forgotPassword(email)).then(res=>setErrorMessage('Password reset link sent, Please Check your mail')).catch(err=>console.log(err));
      }else{
        setErrorMessage('Mail is required')
      }
    };
  return (
    <OverviewWrapper>
      <Helmet>
        <title>Forget Password page | CliClaim</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" py={5} alignItems="center">
          <Logo />
        </Box>
        <Card sx={{ p: 10, mb: 10, borderRadius: 12 }}>
    <Container maxWidth="md" sx={{ textAlign: 'center' }}>
      <Grid
        spacing={{ xs: 12, md: 6 }}
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item md={10} lg={8} mx="auto">
          <TypographyH1 sx={{ mb: 2 }} variant="h1">
            Forgot Password
          </TypographyH1>
          <TypographyH2
            sx={{ lineHeight: 1.5, pb: 4 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            If you are already a member, you can reset your password with your email address.
          </TypographyH2>
          {errorMessage && (
          <Typography variant="body2" color="error" marginTop={1}>
            {errorMessage}
          </Typography>
        )}
          <div>
            <TextField
              fullWidth 
              required
              id="outlined-required"
              label="Email Address"
              onChange={(e)=>setEmail(e?.target?.value)}
            />
          </div>
          <br/>
          <Button
          //  component={RouterLink}
         //   to="/dashboards/tasks"
            size="large"
            variant="contained"
            onClick={forgetSubmit}
            disabled={email===''}
          >
            Submit
          </Button>

          <Button
            sx={{ ml: 2 }}
            href="/"
            size="large"
            variant="text"
          >
            Cancel
          </Button>
                
        </Grid>
      </Grid>
    </Container>
    </Card>
      </Container>
    </OverviewWrapper>
  );
}

export default ForgotPassword;
