import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { driver } from 'driver.js';
import { useEffect, useState } from 'react';
import "driver.js/dist/driver.css";

const SidebarLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const firstVisit = document.cookie.split(';').find(c => c.trim().startsWith('firstVisit=')).slice(-4);
  const nbVisitCookie = document.cookie.split(';').find(c => c.trim().startsWith('nbVisit='));
  const nbVisit = nbVisitCookie? nbVisitCookie.slice(-1) : "0";
  useEffect(()=>{
    if(firstVisit==="true" && nbVisit==="0"){
      const driverObj = driver({
        popoverClass: "driverjs-theme",
        showProgress: true,
        showButtons: ['next', 'previous'],
        steps: [
          { element: '#general', popover: { title: 'General', description: 'Here is an analytical overview of the company.', side: "left", align: 'start' }},
          { element: '#claims', popover: { title: 'Claims', description: 'It shows the claims submitted to the company.',side: "left", align: 'start' }},
          { element: '#claimshistory', popover: { title: 'ClaimsHistory', description: 'It shows the archived  claims  submitted to the company.',side: "left", align: 'start' }},
          { element: '#managers', popover: { title: 'Managers', description: 'Here is an overview of the list of managers. You can add, block, unblock, and delete manager accounts.', side: "left", align: 'start' }},
          { element: '#responsible', popover: { title: 'Responsibles', description: 'The list of individuals responsible is presented here. As an admin, you have the ability to add, block, unblock, and delete responsible accounts.', side: "left", align: 'start' }},
          { element: '#claimmanager', popover: { title: 'Claim managers', description: 'The list of claims managers is showcased here. As an administrator, you have the capability to add, block, unblock, and delete accounts for claims managers.', side: "left", align: 'start' }},
        
          { element: '#collaborationsReceived', popover: { title: ' Receieved Collaborations', description: 'The list of Received collaborations is showcased here. As an administrator, you have the capability to view all the received collaborations.', side: "left", align: 'start' }},
          { element: '#collaborationssent', popover: { title: 'Statut', description: 'The list of Sent Collaorations is showcased here. As an administrator, you have the capability to view all the sent collaborations and their status.', side: "left", align: 'start' }},
          { element: '#ListStaff', popover: { title: 'List of Staff', description: 'The list of staff is showcased here. As an administrator, you have the capability to view list of staff ,click on each staff to view their profile , the calendar and the requests.', side: "left", align: 'start' }},
          { element: '#messenger', popover: { title: 'Messenger', description: 'The Messenger is showcased here. As an administrator, you have the capability to communicate with all the staff in your company.', side: "left", align: 'start' }},
          { element: '#profile', popover: { title: 'Profile', description: 'Your profile is presented here, where you can update your information and change the password.', side: "left", align: 'start' }},
          { element: '#company', popover: { title: 'Company', description: 'You can manage your company here by modifying the related information, suggesting a service, and adding pictures.', side: "left", align: 'start' }},
          { element: '#amani', popover: { title: 'User settings', description: 'Your name and role are visible here. You can access both your profile and your company s settings.', side: "left", align: 'start' }},
        ]
      });
      driverObj.drive()
    }
    document.cookie = 'firstVisit=false; path=/; expires=Thu, 01 Jan 2030 00:00:00 GMT';
    document.cookie = 'nbVisit=1; path=/; expires=Thu, 01 Jan 2030 00:00:00 GMT';
   
  },[])
  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
