import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  responsibles: [],
  selected_responsible: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: [],
};



 export const fetchResponsible = createAsyncThunk('responsible/fetchresponsible', async (params, { rejectWithValue, dispatch }) => {
   try {
     const response = await api.get('/responsible', { params });
     console.log(JSON.stringify(response.data) + "amani");
     console.log(response)
     return response.data;
   } catch (error) {
     if (error.response.status === 401) {
       dispatch(logout());
     }
     if (!error.response) {
       console.log(error)
       throw error;
     }
     console.log(error.response.data)
     return rejectWithValue(error.response.data);
   }
 });
// export const fetchResponsible = createAsyncThunk(
 //  'responsible/fetchresponsible',
 //  async (companyId, { rejectWithValue, dispatch }) => {
 //    try {
//       const response = await api.get(`/responsible/byCompany/${companyId}`);
    
//       console.log(JSON.stringify(response.data) + "amani");
//       return response.data;
//     } catch (error) {
//       if (error.response.status === 401) {
//         dispatch(logout());
//       }
//       if (!error.response) {
//         console.log(error)
//         throw error;
//       }
//       console.log(error.response.data)
//       return rejectWithValue(error.response.data);
//     }
//   }
// );


export const addResponsible = createAsyncThunk('responsible/addResponsible',
async ({email, phone, password, firstname, lastname, services}, { rejectWithValue, dispatch}) => {
       try {
    const response = await api.post('/responsible', {email, phone, password, firstname, lastname, services});
    window.location.reload()
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout()); 
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getResponsibleById = createAsyncThunk('responsible/getResponsibleById', async (responsibleId, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/responsible/${responsibleId}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout()); 
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteResponsible = createAsyncThunk('responsible/deleteResponsible', async (responsibleId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.delete(`/responsible/${responsibleId}`);

    dispatch(fetchResponsible());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const blockResponsible = createAsyncThunk('responsible/blockClaimManager', async (ClaimManagerId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.put(`/responsible/block/${ClaimManagerId}`);
    dispatch(fetchResponsible());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});


const responsibleSlice = createSlice({
  name: 'responsible',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchResponsible.pending]: (state) => {
      state.loading = true;
    },
    [fetchResponsible.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.responsibles = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    [fetchResponsible.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [blockResponsible.pending]: (state) => {
      state.loading = true;
    },
    [blockResponsible.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [blockResponsible.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [deleteResponsible.pending]: (state) => {
      state.loading = true;
    },
    [deleteResponsible.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteResponsible.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addResponsible.pending]: (state) => {
      state.loading = true;
    },
    [addResponsible.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newResponsible = { ...payload, _DT_RowId: payload._id }; 
      state.responsibles.push(newResponsible); 
      state.loading = false;
    },
    [addResponsible.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getResponsibleById.pending]: (state) => {
      state.loading = true;
    },
    [getResponsibleById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_responsible = payload;
      state.loading = false;
    },
    [getResponsibleById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },


  }

});

export default responsibleSlice.reducer;
