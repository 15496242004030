import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
  claims: [],
  claim: null,
  loading: false,
  isSuccess: false,
  message:'',
  errors: [],
};
// export const fetchClaims = createAsyncThunk('claims/fetchClaims', async (params, { rejectWithValue, dispatch }) => {
// try {
//     const response = await api.get('/claim/userClaims', { params });
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     if (error.response.status === 401) {
//       dispatch(logout());
//     }
//     if (!error.response) {
//       console.log(error)
//       throw error;
//     }
//     console.log(error.response.data+"rejectedErrorBaher")
//     return rejectWithValue(error.response.data);
//   }
// });
export const fetchClaims = createAsyncThunk('claims/fetchClaims', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/claim', { params });
    console.log('Response:', response.data);
    // console.log(params+"hamma");
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      console.log(error)
      throw error;
    }
    console.log(error.response.data+"rejectedErrorBaher")
    return rejectWithValue(error.response.data);
  }
});
export const fetchClaimByID = createAsyncThunk('claims/fetchClaimByID', async (id, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/claim/getDetailClaims/${id}`);
    console.log(JSON.stringify(response)+"response")
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const addMessage = createAsyncThunk('claims/addMessage', async ({ id, message }, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.post(`/claim/${id}/add-message`, { message });
    console.log(response+"he")
    return response.data;
    
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const addMessagetreated = createAsyncThunk('claims/addMessagetreated', async ({ id, message }, { rejectWithValue, dispatch }) => {
   try {

     const response = await api.post(`/claim/${id}/add_message_treated`, { message });
     return response.data;
   } catch (error) {
     if (error.response.status === 401) {
       dispatch(logout());
     }
     if (!error.response) {
       throw error;
     }
     return rejectWithValue(error.response.data);
   }
 });




export const AddMediaMessage = createAsyncThunk('claim/AddMediaMessage', async (params, { rejectWithValue }) => {
  const{formData,id}=params
  try {
    
      const response = await api.post(`/claim/${id}/add-files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(fetchClaimByID(id));
      console.log("data received", JSON.stringify(response));
      return response.data;
    } catch (error) {
        
      return rejectWithValue(error.response.data);
    }
});
export const updateStatusClaim = createAsyncThunk('claims/updateStatusClaim', async (params, { rejectWithValue, dispatch }) => {
  try {
    const { id, status } = params
    const response = await api.put(`/claim/${id}/${status}/update-status`).then(() => fetchClaims());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const unarchive = createAsyncThunk('claims/unarchive',async (claimId, { rejectWithValue, dispatch }) => {
    try {
      console.log('claimIdsayyeunarchive: ' + claimId);
      const response = await api.put(`/claim/${claimId}/unarchive`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const archive = createAsyncThunk('claims/archive',async (claimId, { rejectWithValue, dispatch }) => {
  try {
    console.log('claimIdsayyearchive: ' + claimId);
    const response = await api.put(`/claim/${claimId}/archive`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
}
);


export const fetchClaimBarchivedAll = createAsyncThunk( 'claim/fetchClaimBarchivedAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/claim/archivedAll/');
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);







export const blockClient = createAsyncThunk('user/block', async (claim, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.put(`/user/block/${claim.added_by[0]?._id}`);
    return fetchClaimByID(claim?._id);
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const unblockClient = createAsyncThunk('user/unblock', async (claim, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.put(`/user/unblock/${claim.added_by[0]?._id}`);  
    return fetchClaimByID(claim?._id);
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
const claimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClaims.pending]: (state) => {
      state.loading = true;
    },
    [fetchClaims.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.claims = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    [fetchClaims.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchClaimByID.pending]: (state) => {
      state.loading = true;
    },
    [fetchClaimByID.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.claim = payload;

      state.loading = false;
    },
    [blockClient.pending]: (state) => {
      state.loading = true;
    },
    [blockClient.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [blockClient.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [unblockClient.pending]: (state) => {
      state.loading = true;
    },
    [unblockClient.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [unblockClient.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [fetchClaimByID.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addMessage.pending]: (state) => {
      state.loading = true;
    },
    [addMessage.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.message = payload;
      
      state.loading = false;
    },
    [addMessage.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [AddMediaMessage.pending]: (state) => {
      state.loading = true;
    },
    [AddMediaMessage.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [AddMediaMessage.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateStatusClaim.pending]: (state) => {
      state.loading = true;
    },
    [updateStatusClaim.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [updateStatusClaim.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [archive.pending]: (state) => {
      state.loading = true;
    },
    [archive.fulfilled]: (state, { payload }) => {
      console.log(payload + "hamma")
      state.isSuccess = true;
      state.loading = false;
    },
    [archive.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [unarchive.pending]: (state) => {
      state.loading = true;
    },
    [unarchive.fulfilled]: (state, { payload }) => {
     state.isSuccess = true;
      state.loading = false;
    },
    [addMessagetreated.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },  [unarchive.pending]: (state) => {
      state.loading = true;
    },
    [addMessagetreated.fulfilled]: (state, { payload }) => {
      console.log(payload + "unarchive")
      state.isSuccess = true;
      state.loading = false;
    },
    [addMessagetreated.rejected]: (state, { payload }) => {
      if (payload?.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },


  }

});

export default claimsSlice.reducer;