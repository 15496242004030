import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
    claimshistory: [],
    loading: false,
    isSuccess: false,
    errors: [],
};


export const fetchHistory = createAsyncThunk('history/fetchHistory', async ({ rejectWithValue, dispatch }) => {
    try {
        const response = await api.get('/claimhistory');
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(logout());
        }
        if (!error.response) {
            throw error;
        }
        return rejectWithValue(error.response.data);
    }
});

export const fetchHistoryByClaim = createAsyncThunk('claims/fetchHistoryByClaim', async (id, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.get(`/claimhistory/${id}`);
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(logout());
        }
        if (!error.response) {
            throw error;
        }
        return rejectWithValue(error.response.data);
    }
});

export const addhistory = createAsyncThunk('claims/addHistory', async ({claim, action}, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.post(`/claimhistory`, { claim, action });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(logout());
        }
        if (!error.response) {
            throw error;
        }
        return rejectWithValue(error.response.data);
    }
});

const claimsHistorySlice = createSlice({
    name: 'claimshistory',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchHistory.pending]: (state) => {
            state.loading = true;
        },
        [fetchHistory.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.claimshistory = payload?.data || [];
            state.loading = false;
        },
        [fetchHistory.rejected]: (state, { payload }) => {
            if (payload?.statusCode === 400) {
                state.errors = payload?.message;
            }
            state.isSuccess = false;
            state.loading = false;
        },
        [fetchHistoryByClaim.pending]: (state) => {
            state.loading = true;
        },
        [fetchHistoryByClaim.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.claimshistory = payload;
            state.loading = false;
        },
        [fetchHistoryByClaim.rejected]: (state, { payload }) => {
            if (payload?.statusCode === 400) {
                state.errors = payload?.message;
            }
            state.isSuccess = false;
            state.loading = false;
        },
        [addhistory.pending]: (state) => {
            state.loading = true;
        },
        [addhistory.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.loading = false;
        },
        [addhistory.rejected]: (state, { payload }) => {
            if (payload?.statusCode === 400) {
                state.errors = payload?.message;
            }
            state.isSuccess = false;
            state.loading = false;
        },
        [addhistory.pending]: (state) => {
            state.loading = true;
        }
    }

});

export default claimsHistorySlice.reducer;