import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  claimManagers: [],
  selected_claimManager: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: [],
};


export const fetchClaimManager = createAsyncThunk('claimManager/fetchClaimManager', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/claimManager', { params });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const addClaimManager = createAsyncThunk('claimManager/addClaimManager',
  async ({ email, phone, password, firstname, lastname, services }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('/claimManager', { email, phone, password, firstname, lastname, services });
      window.location.reload()
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  });
export const getClaimManagerById = createAsyncThunk('claimManager/getClaimManagerById', async (ClaimManagerId, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/claimManager/${ClaimManagerId}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteClaimManager = createAsyncThunk('claimManager/deleteClaimManager', async (ClaimManagerId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.delete(`/claimManager/${ClaimManagerId}`);

    dispatch(fetchClaimManager());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const blockClaimManager = createAsyncThunk('claimManager/blockClaimManager', async (ClaimManagerId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.put(`/claimManager/block/${ClaimManagerId}`);
    dispatch(fetchClaimManager());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});


const claimManagerSlice = createSlice({
  name: 'claimManager',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClaimManager.pending]: (state) => {
      state.loading = true;
    },
    [fetchClaimManager.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.claimManagers = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    [fetchClaimManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [deleteClaimManager.pending]: (state) => {
      state.loading = true;
    },
    [deleteClaimManager.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteClaimManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [blockClaimManager.pending]: (state) => {
      state.loading = true;
    },
    [blockClaimManager.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [blockClaimManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addClaimManager.pending]: (state) => {
      state.loading = true;
    },
    [addClaimManager.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newClaimManager = { ...payload, _DT_RowId: payload._id }; // Create a new service object with _DT_RowId attribute
      state.claimManagers.push(newClaimManager); // Add the new service to the services array
      state.loading = false;
    },
    [addClaimManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getClaimManagerById.pending]: (state) => {
      state.loading = true;
    },
    [getClaimManagerById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_claimManager = payload;
      state.loading = false;
    },
    [getClaimManagerById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },


  }

});

export default claimManagerSlice.reducer;
