import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';

const CustomScrollbar = forwardRef(({ className, children, ...rest }, ref) => {
  const theme = useTheme();
  const scrollbarsRef = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      if (scrollbarsRef.current) {
        scrollbarsRef.current.scrollToBottom();
      }
    }
  }));

  return (
    <Scrollbars
      autoHide
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              background: `${theme.colors.alpha.black[10]}`,
              borderRadius: `${theme.general.borderRadiusLg}`,
              transition: `${theme.transitions.create(['background'])}`,
              '&:hover': {
                background: `${theme.colors.alpha.black[30]}`
              }
            }}
          />
        );
      }}
      ref={scrollbarsRef}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
});

CustomScrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default CustomScrollbar;
