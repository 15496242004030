/* eslint-disable no-unused-vars */
import api from './config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';

const initialState = {
    companies: [],
    loading: false,
    isSuccess: false,
    errors: [],
  };

  export const updatedCompanyStatus = createAsyncThunk('user/confirm', async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.put('/company/status',  params );
  
      // dispatch(fetchCompanies());
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  });
  export const fetchCompanies = createAsyncThunk('companies/fetchCompanies', async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/company', { params });
  
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  });
  const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {},
    extraReducers: {
      [fetchCompanies.pending]: (state) => {
        state.loading = true;
      },
      [fetchCompanies.fulfilled]: (state, { payload }) => {
        state.isSuccess = true;
        state.companies = payload?.data || [];
        state.recordsTotal = payload?.recordsTotal || 0;
        state.loading = false;
      },
      [fetchCompanies.rejected]: (state, { payload }) => {
        if (payload.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      },
      [updatedCompanyStatus.pending]: (state) => {
        state.loading = true;
      },
      [updatedCompanyStatus.fulfilled]: (state, { payload}) => {
        state.isSuccess = true;
        state.companies = state.companies.map((company) =>
        {
          if (company.DT_RowId === payload?._id) {
            const updatedCompany = {
              ...payload,
              DT_RowId: payload._id,
              admin: company.admin,
            };
            return updatedCompany;
            
          } 
            return company;
          
        }
        
      );
        state.loading = false;
      },
      [updatedCompanyStatus.rejected]: (state, { payload }) => {
        if (payload.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      },
  
      
    }
    
  });
  
  export default companiesSlice.reducer;
  