
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ForgotPassword from './content/ForgetPassword/ForgotPassword';

import { useEffect, useState } from 'react';
import LoaderScreen from './LoaderScreen';
// Custom ProtectedRoute component
function ProtectedRoute({ children }) {
  const location = useLocation();
  // const user = localStorage.getItem("accessToken");
  const authUser = useSelector((x) => x.user);
  const PUBLIC_ROUTES = ["/", "/404", "/forgetPassword", "/resetpassword"];

  const isPublicPage = PUBLIC_ROUTES.includes(location.pathname);
  if (!authUser.accessToken && !isPublicPage) {
    // Redirect the user to a login page or a specific route
    return window.location.href=process.env.REACT_APP_ADMIN_URL+"/login";
  }

  // Render the protected component if the user is authenticated
  return children;
}


function App() {
  const content = useRoutes(router);

  const [isLoading, setIsLoading] = useState(false);

 
  return (
    <ThemeProvider>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ProtectedRoute>
        {isLoading ? <LoaderScreen /> : null}

        {content}
        </ProtectedRoute>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
