import React, { useEffect, useRef, useState } from 'react';
import { socket } from 'src/socket/config';
import {
    Box,
    Divider,
    List,
    ListItem,
    Popover,
    Typography
  } from '@mui/material';
import jwt_decode from 'jwt-decode';
import soundUrl from "../assets/sound/notif1.mp3";


const Notification = ({setNotifications}) => {

const company=localStorage.getItem("company")
const [isOpen,setIsOpen]=useState(false)
const [data,setData]=useState('')
  useEffect(() => {
    const audio = new Audio(soundUrl);
    const decode= jwt_decode(localStorage.getItem('accessToken'));
    socket.on("suggestionNotification", (data) => {
        if(data && data.user===decode.email){
            setData(data)
            setNotifications((prevNotifications) => [...prevNotifications, data]);
            setIsOpen(true)
            audio.play();
            setTimeout(()=> setIsOpen(false),4000)
        }
    });
    socket.on("claimNotification", (data) => {
        if(data && data.company===company){
            setData(data)
            setNotifications((prevNotifications) => [...prevNotifications, data]);
            setIsOpen(true)
            audio.play();
            setTimeout(()=> setIsOpen(false),4000)
        }
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
    <Popover
        anchorEl={''}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontWeight: 'bold' }}>
                  {data.kind}
                </Typography>
                <Typography variant="caption" sx={{ textTransform: 'none' }}>
                  1 Second ago
                </Typography>
              </Box>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {' '}
                {data.message}
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default Notification;