import React, { useEffect, useState } from 'react';

const LoaderScreen = () => {
  const [animations, setAnimations] = useState({
    one: 0,
    two: 0
  });

  const ballImage = './assets/images/loader1.jpg';
  const ballImage1 = './assets/images/loader2.jpg';

  const onStartAnimation = () => {
    const animationDuration = 500;

    // Premier mouvement des balles
    const firstMovement = [
      { name: 'one', toValue: -10 },
      { name: 'two', toValue: 10 }
    ];

    // Deuxième mouvement des balles
    const secondMovement = [
      { name: 'one', toValue: 0 },
      { name: 'two', toValue: 0 }
    ];

    const animate = (movement) => {
      return Promise.all(
        movement.map(({ name, toValue }) => {
          return new Promise((resolve) => {
            const startValue = animations[name];
            const change = toValue - startValue;
            let startTime;

            const step = (timestamp) => {
              if (!startTime) startTime = timestamp;
              const deltaTime = timestamp - startTime;
              const newValue = startValue + (deltaTime / animationDuration) * change;

              setAnimations(prevState => ({
                ...prevState,
                [name]: newValue
              }));

              if (deltaTime < animationDuration) {
                requestAnimationFrame(step);
              } else {
                resolve();
              }
            };

            requestAnimationFrame(step);
          });
        })
      );
    };

    animate(firstMovement).then(() => {
      animate(secondMovement).then(() => {
        onStartAnimation(); // Pour boucler l'animation
      });
    });
  };

  useEffect(() => {
    onStartAnimation();
  }, []);

  return (
    <div style={LoaderStyle.container}>
      <div style={LoaderStyle.BallsContainer}>
        <img
          src={ballImage}
          alt="Ball"
          style={{
            width: 20,
            height: 40,
            transform: `translateY(${animations.two}px)`
          }}
        />
        <div style={{ width: 2 }} />
        <img
          src={ballImage1}
          alt="Ball"
          style={{
            width: 20,
            height: 40,
            top: 10,
            transform: `translateY(${animations.one}px)`
          }}
        />
      </div>
    </div>
  );
};

export default LoaderScreen;
