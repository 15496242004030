import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_API_BASE_SOCKET_URL;
const authToken = localStorage.getItem('accessToken');


export const socket = io(URL, {
    extraHeaders: {
      Authorization: `Bearer ${authToken}`,
    },
  });