import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config'; // Import the configured Axios instance

const initialState = {
  managers: [],
  selected_manager: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: [],
};


export const fetchManager = createAsyncThunk('manager/fetchmanager', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.get('/manager', { params });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const addManager = createAsyncThunk('manager/addManager',
async ({email, phone, password, firstname, lastname, services, token}, { rejectWithValue, dispatch}) => {
       try {
    const response = await api.post('/manager', {email, phone, password, firstname, lastname, services});
    window.location.reload()
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout()); 
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getManagerById = createAsyncThunk('manager/getManagerById', async (managerId, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/manager/${managerId}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout()); 
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteManager = createAsyncThunk('manager/deleteManager', async (managerId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.delete(`/manager/${managerId}`);

    dispatch(fetchManager());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const blockManager = createAsyncThunk('manager/blockClaimManager', async (ClaimManagerId, { rejectWithValue, dispatch }) => {
  try {
    const response = await api.put(`/manager/block/${ClaimManagerId}`);
    dispatch(fetchManager());
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});


const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchManager.pending]: (state) => {
      state.loading = true;
    },
    [fetchManager.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.managers = payload?.data || [];
      state.recordsTotal = payload?.recordsTotal || 0;
      state.loading = false;
    },
    [fetchManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [deleteManager.pending]: (state) => {
      state.loading = true;
    },
    [deleteManager.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [deleteManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [blockManager.pending]: (state) => {
      state.loading = true;
    },
    [blockManager.fulfilled]: (state) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [blockManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [addManager.pending]: (state) => {
      state.loading = true;
    },
    [addManager.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      const newManager = { ...payload, _DT_RowId: payload._id }; // Create a new service object with _DT_RowId attribute
      state.managers.push(newManager); // Add the new service to the services array
      state.loading = false;
    },
    [addManager.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [getManagerById.pending]: (state) => {
      state.loading = true;
    },
    [getManagerById.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.selected_manager = payload;
      state.loading = false;
    },
    [getManagerById.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },


  }

});

export default managerSlice.reducer;
