import { useEffect, useRef, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { getProfile } from 'src/redux/authSlice';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
/* import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'; */
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/redux/authSlice';
import api from 'src/redux/config';
import { useTranslation } from 'react-i18next';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((x) => x.user);
  const [imageData, setImageData] = useState(null);
  const handleLogout = () => {
    dispatch(logout());

    navigate('/');
  };

  const user = {
    firstname :  authUser?.profile?.firstname ?  `${authUser?.profile?.firstname}` : '',
    lastname :  authUser?.profile?.lastname ?  `${authUser?.profile?.lastname}` : '',
    email :  authUser?.profile?.email ?  `${authUser?.profile?.email}` : '',

    /* name:
      authUser?.profile?.firstname && authUser?.profile?.lastname
        ? `${authUser?.profile?.firstname} ${authUser?.profile?.lastname}`
        : authUser?.profile?.email || '', */
    avatar: '/static/images/avatars/4.jpg',
    jobtitle: authUser?.profile?.userKind || ''
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
const{t}=useTranslation()
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  useEffect(() => {
    let isMounted = true;

    api
      .get(`/user/profile-picture`, {
        headers: {
          Authorization: authUser?.accessToken
        },
        responseType: 'arraybuffer'
      })
      .then((response) => {
        if (isMounted) {
          const arrayBufferView = new Uint8Array(response.data);
          const blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          setImageData(imageUrl);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error fetching profile picture:', error);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <UserBoxButton
        color="secondary"
        ref={ref}
        onClick={handleOpen}
        id="amani"
        style={{ marginLeft: -10 }}
      >
        {imageData ? (
          <Avatar
            style={{ width: '30px', height: '30px',borderRadius: '50%' }}
            variant="rounded"
            alt={user.name}
            src={imageData}
           
           
          />
        ) : (
          <Avatar
            variant="rounded"
            alt="f"
            src={require('../../../../assets/images/profile.png')}
            style={{ width: '30px', height: '30px' ,borderRadius: '50%'}}
          />
        )}
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.firstname?.charAt(0).toUpperCase()+authUser?.profile?.firstname?.slice(1)} {user?.lastname.toUpperCase()}
              </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover

        anchorEl={ref?.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'

          
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">

          <Avatar variant="rounded" alt={user?.firstname} src={imageData}
            style={{ borderRadius: '50%' }} />
          <UserBoxText>
            <UserBoxLabel variant="body1">
            {user?.firstname?.charAt(0).toUpperCase()+authUser?.profile?.firstname?.slice(1)} {user?.lastname.toUpperCase()}

            </UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {/* <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem> */}

          <ListItem
            button
            to="/management/profile/settings"
            component={NavLink}

            onClick={handleClose}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>

          <ListItem
            button
            to="/management/company/settings"
            component={NavLink}

            onClick={handleClose}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Company Settings')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>

          <Button color="primary" fullWidth onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 1 }} />
         {t('Exit')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
