import { createSlice , createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import api from './config';

export const forgotPassword = createAsyncThunk('forgotPassword/forgotPassword',async (email, thunkAPI) => {
    try {

      const response = await api.post('/auth/forgot-password', { email });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
//getreset
export const getresetPassword = createAsyncThunk('forgotPassword/gettoken',async ({ token }, thunkAPI) => {
    try { 
      const response = await api.get(`/auth/validate-reset-password-token/${token}` );

      const isValidToken = response.data.isValidToken;
      
      if (isValidToken) {
        return true;
      } else {
        return thunkAPI.rejectWithValue('Token is invalid'); 
      }
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);


// Action asynchrone pour "Reset Password"
export const resetPassword = createAsyncThunk('forgotPassword/setNewPassword',async ({ token, password, passwordConfirm }, thunkAPI) => {
    try {
      const response = await api.post(`/auth/reset-password-from-email`,{token, password, passwordConfirm});
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
// Slice pour la fonction "Forgot Password"
const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    loading: false,
    error: null,
    success: false,
    newPasswordData: null,
  },
  reducers: {
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.errors=[];
      state.profile={};
      localStorage.clear();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.newPasswordData = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.payload;
        state.success = false;
      });
  },
});
  
export default forgotPasswordSlice.reducer;

