import {

  Typography
} from '@mui/material';



function HeaderMenu() {


  return (
    <>
      <Typography variant="h6" component="h6"/>
    
    </>
  );
}

export default HeaderMenu;
