import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from './authSlice';
import api from './config';
import { store } from './store';
const initialState = {
  suggestions: [],
  selected_suggestions: null,
  loading: false,
  isSuccess: false,
  recordsTotal: 0,
  errors: [],
};

export const addSuggestion = createAsyncThunk('suggestions/addSuggestion', async (suggestionData, { rejectWithValue, dispatch }) => {
    try {
  
      const params = { name: suggestionData.serviceName, business_area: suggestionData.business_name, active: suggestionData.active };
      const response = await api.post('/Suggestions', params);
      
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  });

const suggestionSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    resetIsSuccess: (state) => {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [addSuggestion.pending]: (state) => {
        state.loading = true;
      },
      [addSuggestion.fulfilled]: (state, { payload }) => {
        state.isSuccess = true;
        state.loading = false;
        setTimeout(() => {
          store.dispatch(suggestionSlice.actions.resetIsSuccess());
        }, 5000);
      },
      [addSuggestion.rejected]: (state, { payload }) => {
        if (payload.statusCode === 400) {
          state.errors = payload?.message;
        }
        state.isSuccess = false;
        state.loading = false;
      },
  }

});

export default suggestionSlice.reducer;
