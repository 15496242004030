import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import servicesSlice from './servicesSlice';
import companiesSlice from './companiesSlice';
import claimsSlice from './claimsSlice';
import forgotPasswordSlice from './forgotPasswordSlice';
import managerSlice from './managerSlice';
import responsibleSlice from './responsibleSlice';
import suggestionSlice from './suggestionSlice';
import claimManagerSlice from './claimManagerSlice';
import claimHistorySlice from './claimHistorySlice';

export const store = configureStore({
  reducer: {
    user: authSlice,
    manager: managerSlice,
    claimManager: claimManagerSlice,
    responsible: responsibleSlice,
    services: servicesSlice,
    companies: companiesSlice,
    claims: claimsSlice,
    forgotPassword: forgotPasswordSlice,
    suggestions:suggestionSlice,
    history: claimHistorySlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
