import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './config';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import config from "./config.json";
const storedAccessToken = localStorage.getItem('accessToken');

let decodedEmail = '';
let decodePhone = '';
let decodedRole = '';
if (storedAccessToken) {
  const decodedToken = jwt_decode(storedAccessToken);

  decodedEmail = decodedToken.email;
  decodePhone = decodedToken.phone;
  decodedRole = decodedToken.role;
}



const initialState = {
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  profile: {},
  loading: false,
  isSuccess: false,
  errors: [],
  role: decodedRole
};


export const signin = createAsyncThunk('auth/signin', async (body, { rejectWithValue }) => {
  try {
    const params = { email: body.email, password: body.password };
    const response = await api.post('/auth/signin', params);

    // Store the data in localStorage
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    const decoded = jwt_decode(response.data.accessToken);
    localStorage.setItem('role', decoded.role);

    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getProfile = createAsyncThunk('user/profile', async (__, { rejectWithValue, dispatch }) => {
  try {

    const response = await api.get(`/user/profile`);
    // localStorage.setItem("company",response.data.company._id)
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});


export const updateProfile = createAsyncThunk('user/updateprofile', async (body, { rejectWithValue, dispatch }) => {
  try {
    const params = { email: body.email, phone: body.phone, firstname: body.firstname, lastname: body.lastname };
    const response = await api.put(`/user/profile`, params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updatePassword = createAsyncThunk('user/updatePassword', async (body, { rejectWithValue, dispatch }) => {
  try {
    const params = { old_password: body.old_password, password: body.password, passwordConfirm: body.passwordConfirm };
    const response = await api.put(`/user/change-password`, params);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout());
    }
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const UpdateCompany = createAsyncThunk('profile/updateCompany', async (formData, { rejectWithValue }) => {
  try {
    const response = await api.put('/company', formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }

});

export const DeleteCompanyMedia = createAsyncThunk('company/DeletMedia', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`/company/media/${id}`);
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }

});

export const DeleteCompanyPicture = createAsyncThunk('company/DeletPicture', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`/company/picture/${id}`);
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }

});
export const DeleteCompanyCover = createAsyncThunk('company/DeleteCover', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`/company/couverture/${id}`);
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }

});
export const AddCompanyMedia = createAsyncThunk('company/AddMedia', async (formData, { rejectWithValue }) => {
  try {

    const response = await api.post('/company/media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }
});
export const AddCompanyPicture = createAsyncThunk('company/AddPicture', async (formData, { rejectWithValue }) => {
  try {

    const response = await api.post('/company/pictures', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }
});
export const AddCompanyCover = createAsyncThunk('company/AddCover', async (formData, { rejectWithValue }) => {
  try {

    const response = await api.post('/company/couverture', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }
});
export const AddCompanyMainPic = createAsyncThunk('company/AddMainPic', async (formData, { rejectWithValue }) => {
  try {

    const response = await api.post('/company/photoprincipal', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {

    return rejectWithValue(error.response.data);
  }
});

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${config.REACT_APP_COOKIES_HOST}; path=/;`;
}
// user 
const authSlice = createSlice({
  name: 'superadmin',
  initialState,
  reducers: {

    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.errors = [];
      state.profile = {};
      localStorage.clear();
      deleteCookie('accessToken');
      deleteCookie('refreshToken');
      window.location.href = `${config.REACT_APP_ADMIN_URL}/login`
    }
  },
  extraReducers: {
    [signin.pending]: (state) => {
      state.loading = true;
    },
    [signin.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      if (!payload?.accessToken || !payload?.refreshToken) {
        state.isSuccess = false;
        state.errors = ['AccessToken and/or refreshToken not found'];
      }
      else {
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        const decodedToken = jwt_decode(payload.accessToken);
        state.email = decodedToken.email;
        state.role = decodedToken.role;
      }
      if (state.role !== "ADMIN" || state.role !== "MANAGER" || state.role !== "RESPONSIBLE") {
        state.errors = ['Without authorization, you do not have privileges.'];
      }


      state.loading = false;
    },
    [signin.rejected]: (state, { payload }) => {
      // if (payload.statusCode === 400) {
      state.errors = payload?.message;
      // }
      state.isSuccess = false;
      state.loading = true;
    },
    [getProfile.pending]: (state) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile = payload;
      state.loading = false;
    },
    [getProfile.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile = payload;
      state.loading = false;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    // eslint-disable-next-line no-unused-vars
    [updatePassword.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.loading = false;
    },
    [updatePassword.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [DeleteCompanyMedia.pending]: (state) => {
      state.loading = true;
    },
    [DeleteCompanyMedia.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [DeleteCompanyMedia.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [DeleteCompanyCover.pending]: (state) => {
      state.loading = true;
    },
    [DeleteCompanyCover.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [DeleteCompanyCover.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [DeleteCompanyPicture.pending]: (state) => {
      state.loading = true;
    },
    [DeleteCompanyPicture.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [DeleteCompanyPicture.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [UpdateCompany.pending]: (state) => {
      state.loading = true;
    },
    [UpdateCompany.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [UpdateCompany.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [AddCompanyMedia.pending]: (state) => {
      state.loading = true;
    },
    [AddCompanyMedia.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [AddCompanyMedia.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [AddCompanyMainPic.pending]: (state) => {
      state.loading = true;
    },
    [AddCompanyMainPic.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [AddCompanyMainPic.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [AddCompanyCover.pending]: (state) => {
      state.loading = true;
    },
    [AddCompanyCover.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [AddCompanyCover.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
    [AddCompanyPicture.pending]: (state) => {
      state.loading = true;
    },
    [AddCompanyPicture.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.profile.company = payload;
      state.loading = false;
    },
    [AddCompanyPicture.rejected]: (state, { payload }) => {
      if (payload.statusCode === 400) {
        state.errors = payload?.message;
      }
      state.isSuccess = false;
      state.loading = false;
    },
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
