import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );



  // Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);


 const MessengerStaff = Loader(

   lazy(() => import('src/content/applications/MessengerStaff'))

 );
  const MessengerALL = Loader(

   lazy(() => import('src/content/applications/Staff/MessengerAll'))

 );
/* const Services = Loader(
  lazy(() => import('src/content/applications/Services'))
);
const Companies = Loader(
  lazy(() => import('src/content/applications/Companies'))
); */


 const Staff = Loader(
   lazy(() => import('src/content/applications/Staff'))
 );

const RCollaborationsRecived = Loader(
  lazy(() => import('src/content/applications/RCollaborationsRecived'))
);
const CollaborationsSent = Loader(
  lazy(() => import('src/content/applications/CollaborationsSent'))
);
   const ClaimsHistory = Loader(
     lazy(() => import('src/content/applications/ClaimsHistory'))
   );
 
const Claims = Loader(
  lazy(() => import('src/content/applications/Claims'))
); 
const Manager = Loader(
  lazy(() => import('src/content/applications/Manager'))
); 
const ClaimManager = Loader(
  lazy(() => import('src/content/applications/ClaimManager'))
); 
const Responsible = Loader(
  lazy(() => import('src/content/applications/Responsible'))
); 
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);

const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const CompanySettings = Loader(
  lazy(() => import('src/content/applications/Company/settings'))
);
// Components

const StaffTable =Loader(lazy(() => import('src/content/applications/Staff/StaffTable')));

const CollaborationsTable =Loader(lazy(() => import('src/content/applications/CollaborationsSent/collaborationsTable')));
const CollaborationsReceivedTable =Loader(lazy(() => import('src/content/applications/RCollaborationsRecived/collaborationsReceivedTable')));
const ClaimsHistoryTable = Loader(lazy(() => import('src/content/applications/ClaimsHistory/ClaimsHistoryTable')));
const ClaimsTable = Loader(lazy(() => import('src/content/applications/Claims/ClaimsTable')));
const ClaimManagersTable = Loader(lazy(() => import('src/content/applications/ClaimManager/ClaimManagerTable')));
const ManagersTable = Loader(lazy(() => import('src/content/applications/Manager/ManagerTable')));
const ResponsibleTable = Loader(lazy(() => import('src/content/applications/Responsible/ResponsibleTable')));
/* const CompaniesTable = Loader(lazy(() => import('src/content/applications/Companies/CompaniesTable')));

const CompanyDetails = Loader(
  lazy(() => import('src/content/applications/Companies/details'))
); */
const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const ClaimDetails = Loader(lazy(() => import('src/content/applications/Claims/details')));

const DetailsStaff = Loader(lazy(() => import('src/content/applications/Staff/DetailsStaff/indexS')));
const ForgetPassword = Loader(lazy(() => import('src/content/ForgetPassword/ForgotPassword')));
const ResetPassword = Loader(lazy(() => import('src/content/ForgetPassword/ResetPassword')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes = [
  {
    path: '' ,
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'forgetPassword',
        element: <ForgetPassword />
      },
      {
        path: 'resetpassword',
        element: <ResetPassword />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Tasks />
      },
     
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'company',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <CompanySettings />
          }
        ]
      },
      /*  {
        path: 'services',
        element: <Services />
      }, */

       {
         path: 'claims',
         element: <Claims />,
         children: [
           {
             path: '',
             element: <ClaimsTable />
           },
          
            {
             path: 'details/:id',
             element: <Messenger />
           } ,
            {
             path: 'detailsClaim/:id',
             element: <ClaimDetails />
           } ,
           {
             path: 'claimshistory',
             element: <ClaimsHistoryTable />
           } 
         ]
        
       },
      
           {
      
             path: 'ClaimsHistory',
      
             element: <ClaimsHistory />,
      
             children: [
      
               {
      
                 path: '',
      
                 element: <ClaimsHistoryTable />
      
             },]
      
         },
      
      {
        path: 'Managers',
        element: <Manager />,
        children: [
          {
            path: '',
            element: <ManagersTable />
          }
        ]
        
      }, 
      {
        path: 'ClaimManagers',
        element: <ClaimManager />,
        children: [
          {
            path: '',
            element: <ClaimManagersTable />
          }
        ]
        
      }, 
      {
        path: 'Responsibles',
        element: <Responsible />,
        children: [
          {
            path: '',
            element: <ResponsibleTable />
          }
        ]

      }, 
      {
        path: 'management/ReceivedCollab',
        element: <RCollaborationsRecived />,
        children: [
          {
            path: '',
            element: <CollaborationsReceivedTable />
          }
        ]
        
      }, 
       {
         path: 'management/SentCollab',
         element: <CollaborationsSent />,
         children: [
           {
             path: '',
             element: <CollaborationsTable />
           }
         ]
        
       }, 
       {
        path: 'staff',
        element: <Staff />,
        children: [
          {
            path: '',
            element: <StaffTable />
          },
          {
            path: 'detailS/:id',
            element: <MessengerStaff />
          } ,
          {
            path: 'detailsStaff/:id',
            element: <DetailsStaff />
          },
         
            {
              path: 'messenger',
              element: <MessengerALL />
            } ,
        ]
      }, 
      
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />
          },
       /*    {
            path: 'details',
            element: <UserProfile />
          }, */
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },

  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
