import { useContext, useState } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useNavigate } from 'react-router-dom';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupIcon from '@mui/icons-material/Group';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';
import GroupsIcon from '@mui/icons-material/Groups';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ForumIcon from '@mui/icons-material/Forum';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/authSlice';
import { useTranslation } from 'react-i18next';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

  .MuiListSubheader-root {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.colors.alpha.trueWhite[50]};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 0.2px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((x) => x.user.role);
  const { closeSidebar } = useContext(SidebarContext);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
  };

  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t('sidebar.dashboard')}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div" id="general">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/tasks"
                  startIcon={<BrightnessLowTwoToneIcon style={{ color: "#18E68E" }} />}
                >
                  {t('sidebar.general')}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t('sidebar.management')}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div" id="claims">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/claims"
                  startIcon={<TableChartTwoToneIcon style={{ color: "#18E68E" }} />}
                >
                  {t('sidebar.claims')}
                </Button>
              </ListItem>
              <ListItem component="div" id="claimshistory">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/claimsHistory"
                  startIcon={<UnarchiveIcon style={{ color: "#18E68E" }} />}
                >
                  {t('sidebar.claims_history')}
                </Button>
              </ListItem>

              {role === "ADMIN" && (
                <ListItem component="div" id="managers">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/managers"
                    startIcon={<ManageAccountsIcon style={{ color: "#18E68E" }} />}
                  >
                    {t('sidebar.managers')}
                  </Button>
                </ListItem>
              )}

              {(role === "ADMIN" || role === "MANAGER") && (
                <ListItem component="div" id="responsible">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/responsibles"
                    startIcon={<PersonIcon style={{ color: "#18E68E" }} />}
                  >
                    {t('sidebar.responsibles')}
                  </Button>
                </ListItem>
              )}
              {role !== "CLAIM_MANAGER" && (
                <ListItem component="div" id="claimmanager">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/ClaimManagers"
                    startIcon={<GroupIcon style={{ color: "#18E68E" }} />}
                  >
                    {t('sidebar.claim_managers')}
                  </Button>
                </ListItem>
              )}
              {role === "ADMIN" && (
                <>
                  <ListItem onClick={handleClick}>
                    <Box display="flex" alignItems="center">
                      <Button
                        sx={{
                          pl: 5,
                          textTransform: 'none',
                          fontWeight: 'bold',
                          color: 'white',
                          justifyContent: 'flex-start',
                          width: '100%',
                        }}
                        startIcon={<WorkspacesIcon sx={{ Pl: 1, color: "#18E68E" }} />}
                      >
                        {t('sidebar.collaborations')}
                      </Button>
                    </Box>
                    {open ? <ExpandLessIcon style={{ color: "#18E68E" }} /> : <ExpandMoreIcon style={{ color: "#18E68E" }} />}
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ height: 4 }} /> {/* Adjust this height to reduce space */}
                    <ListItem sx={{ pl: 6 }} component="div" id="collaborationsReceived">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="management/ReceivedCollab"
                        startIcon={<MarkEmailUnreadRoundedIcon sx={{ ml: 2, color: "#18E68E" }} />}
                        sx={{
                          pl: 5,
                          textTransform: 'none',
                          fontWeight: 'bold',
                          color: 'white',
                          justifyContent: 'flex-start',
                          width: '100%',
                        }}
                      >
                        {t('sidebar.received')}
                      </Button>
                    </ListItem>
                    <List component="div" disablePadding>
                      <ListItem sx={{ pl: 6 }} component="div" id="collaborationssent">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="management/SentCollab"
                          startIcon={<ForwardToInboxIcon sx={{ ml: 2, color: "#18E68E" }} />}
                          sx={{
                            pl: 5,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: 'white',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          {t('sidebar.status')}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
              {role === "ADMIN" && (
                <>
                  <ListItem onClick={handleClick1}>
                    <Box display="flex" alignItems="center">
                      <Button
                        sx={{
                          pl: 5,
                          textTransform: 'none',
                          fontWeight: 'bold',
                          color: 'white',
                          justifyContent: 'flex-start',
                          width: '100%',
                        }}
                        startIcon={<GroupsIcon sx={{ Pl: 1, color: "#18E68E" }} />}
                      >
                        {t('sidebar.staff')}
                      </Button>
                      <Box sx={{ marginLeft: 'auto', marginLeft: '65Px' }}>
                        {open1 ? <ExpandLessIcon style={{ color: "#18E68E" }} /> : <ExpandMoreIcon style={{ color: "#18E68E" }} />}
                      </Box>
                    </Box>
                  </ListItem>
                  <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem sx={{ pl: 6 }} component="div" id="ListStaff">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/management/staff"
                          startIcon={<ListAltIcon sx={{ ml: 2, color: "#18E68E" }} />}
                          sx={{
                            pl: 5,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: 'white',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          {t('sidebar.list_of_staff')}
                        </Button>
                      </ListItem>
                      <ListItem sx={{ pl: 4 }} component="div" id="messenger">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/management/staff/messenger"
                          startIcon={<ForumIcon sx={{ ml: 2, color: "#18E68E" }} />}
                          sx={{
                            pl: 5,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            color: 'white',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          {t('sidebar.messenger')}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t('sidebar.accounts')}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div" id="profile">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/settings"
                  startIcon={<AccountBoxIcon style={{ color: "#18E68E" }} />}
                >
                  {t('sidebar.profile')}
                </Button>
              </ListItem>
              <ListItem component="div" id="company">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/company/settings"
                  startIcon={<ApartmentIcon style={{ color: "#18E68E" }} />}
                >
                  {t('sidebar.company')}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t('sidebar.account')}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  onClick={handleLogout}
                  startIcon={<LogoutIcon style={{ color: "#18E68E" }} />}
                >
                  {t('sidebar.exit')}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
