import {
  alpha,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import { formatDistance } from 'date-fns';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router';
import api from 'src/redux/config';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);


function HeaderNotifications({
  notifications,
  fetchNotification
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationClick = async (notif) => {

    await api
      .put(`/notification/${notif._id}`)
      .then(() => {
        fetchNotification();
      })
      .catch((err) => console.log(err));

    if (notif.kind === 'Suggestion') {
      navigate('/management/company/settings');
      setTimeout(() => window.scrollTo({ top: 1300, behavior: 'smooth' }), 500);
    } else if (notif.kind === 'Claim') {
      navigate('/management/claims');
    }
    handleClose();
  };

  const handleDeleteNotification = async (e, notif) => {
    e.stopPropagation();
    try {
      await api.delete(`/notification/${notif._id}`);
      fetchNotification();
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigateClaimById = async (notif, index) => {
    navigate(`/management/claims/details/${notif}`);
    setIsHovered(index);
  };

  return (
    <>
      <Tooltip arrow title={t("Notifications")}>
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge

            badgeContent={
              notifications.filter((notification) => !notification.view).length
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t("Notifications")}</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {notifications.length > 0
            ? notifications.slice().reverse().map((notif, index) => (
                <ListItem
                  button
                  onClick={() => handleNotificationClick(notif)}
                  key={index}
                  sx={{
                    p: 2,
                    pt: 2,
                    pb: 2,
                    minWidth: 350,
                    display: { xs: 'block', sm: 'flex' },
                    backgroundColor: !notif.view
                      ? 'rgba(151, 181, 151, 0.138)'
                      : 'none'
                  }}
                >
                  <Box
                    flex="1"
                    onClick={() => handleNavigateClaimById(notif?._id, index)}
                  >
                    {isHovered === index && (
                      <Grid
                        container
                        justifyContent="flex-end"
                        marginTop={-1.5}
                        marginLeft={1.6}
                        marginBottom={-0.5}
                      >
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(e) => handleDeleteNotification(e, notif)}
                        >
                          <CloseIcon sx={{ fontSize: 12 }} />
                        </IconButton>
                      </Grid>
                    )}
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {t(notif?.kind)}
                        {!notif?.view && (
                          <FiberManualRecordIcon
                            sx={{
                              fontSize: 12,
                              marginLeft: '5px',
                              color: '#18e68e'
                            }}
                          />
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ textTransform: 'none' }}
                      >
                        {formatDistance(
                          new Date(),
                          new Date(
                            notif?.createdAt ? notif.createdAt : new Date()
                          ),
                          {
                            addSuffix: true
                          }
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {notif?.kind === "Suggestion" ? t("New suggestion has been treated") : t("New claim has been opened")}
                    </Typography>
                  </Box>
                </ListItem>
              ))
            : null}
        </List>

        {notifications.length === 0 && (
          <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontWeight: 'bold' }}>
                  {t("No notifications at the moment.")}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        )}
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderNotifications;
