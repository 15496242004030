import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from "../src/content/applications/translations/en.json";
import translationAR from "../src/content/applications/translations/ar.json";
import translationFR from "../src/content/applications/translations/fr.json";
import translationES from "../src/content/applications/translations/es.json";
import translationDT from "../src/content/applications/translations/dt.json";
import translationPT from "../src/content/applications/translations/pt.json";

const getLanguage = () => {
  const savedLanguage = localStorage.getItem("language");
  return savedLanguage || "en";
};

// Fonction pour enregistrer la langue dans le stockage local
const saveLanguage = (language) => {
  localStorage.setItem("language", language);
};

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  },
   fr: {
     translation: translationFR
   },
   es: {
     translation: translationES
   },
   pt: {
     translation: translationPT
   },
   dt: {
     translation: translationDT
   }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(), // Set the default language to the preferred language
    fallbackLng: "en", // Fallback language if the preferred language is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false // feature in react
    }
  });

i18n.on("languageChanged", (lng) => {
  saveLanguage(lng);
});

export default i18n;
